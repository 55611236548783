<template>
  <div style="background-color: #171726;min-height: 100vh;overflow: scroll;">
    <div v-if="showSearch" class="display_flex justify-content_flex-justify align-items_center padding-lr title_top margin-top-sm">
      <form  class="margin-right flex_1" action="" @submit.prevent="tapToSearch">
        <input class="search_input padding-lr-sm" ref="input" type="search" placeholder="请输入用户ID或昵称" v-model="tetInput">
      </form>
      <span class="search_txt" @click="goBack" style="color: #ffffff;">取消</span>
    </div>
    <template v-else>
      <nav-bar class="padding-top bg-black" :icon="'img/backw.png'" :textColor="'#ffffff'" :backColor="'#171726'" />
      <!-- 直属下级 -->
      <template v-if="listData.length == 1">
        <div class="sh_bg padding-lr-sm margin-sm">
          <div class="sh_box display_flex justify-content_flex-justify align-items_center padding-tb">
            <p>用户ID</p>
            <span>{{listData[0].uid}}</span>
          </div>
          <div class="sh_box display_flex justify-content_flex-justify align-items_center padding-tb">
            <p>用户昵称</p>
            <span>{{listData[0].nickname}}</span>
          </div>
          <div class="sh_box display_flex justify-content_flex-justify align-items_center padding-tb">
            <p>累计赠送礼物</p>
            <div class="display_flex align-items_center margin-top-xs">
                <img src="img/bi.png" alt="">
                <span class="margin-left-xs">{{listData[0].sub_total_value}}</span>
            </div>
          </div>
          <div class="sh_box display_flex justify-content_flex-justify align-items_center padding-tb">
            <p>累计充值</p>
            <span>{{listData[0].total_recharge+'(元)'}}</span>
          </div>
          <div class="sh_box display_flex justify-content_flex-justify align-items_center padding-tb">
            <p>邀请人数</p>
            <span>{{listData[0].sub_invite_sum}}</span>
          </div>
          <!-- <div class="sh_box display_flex justify-content_flex-justify align-items_center padding-tb">
            <p>邀请人累计赠送礼物</p>
            <div class="display_flex align-items_center margin-top-xs">
                <img src="img/bi.png" alt="">
                <span class="margin-left-xs">{{listData[0].s_total_value}}</span>
            </div>
          </div> -->
          <div class="sh_box display_flex justify-content_flex-justify align-items_center padding-tb">
            <p>邀请等级</p>
            <span v-if="listData[0].level== 1">I级</span>
            <span v-if="listData[0].level== 2">II级</span>
          </div>
        </div>
        <!-- <div class="sh_more display_flex justify-content_flex-end align-items_center margin-lr-sm">
          <p @click="goDetail(listData[0])">查看详情</p>
          <img src="img/det.png" alt="">
        </div> -->
      </template>
      <!-- 下下级 -->
      <template v-else>
        <div class="sh_bg padding-lr-sm margin-sm">
          <div class="sh_box display_flex justify-content_flex-justify align-items_center padding-tb">
            <p>用户ID</p>
            <span>{{twoData.u_id}}</span>
          </div>
          <div class="sh_box display_flex justify-content_flex-justify align-items_center padding-tb">
            <p>用户昵称</p>
            <span>{{twoData.nickname}}</span>
          </div>
          <div class="sh_box display_flex justify-content_flex-justify align-items_center padding-tb">
            <p>累计赠送礼物</p>
            <div class="display_flex align-items_center margin-top-xs">
                <img src="img/zuan.png" alt="">
                <span class="margin-left-xs">{{twoData.sub_total_value}}</span>
            </div>
          </div>
          <div class="sh_box display_flex justify-content_flex-justify align-items_center padding-tb">
            <p>邀请等级</p>
            <span>II级</span>
          </div>
        </div>
        <div class="sh_title margin-lr-sm margin-top margin-bottom-sm">TA的上级</div>
        <div class="sh_bg padding-lr-sm margin-sm">
          <div class="sh_box display_flex justify-content_flex-justify align-items_center padding-tb">
            <p>用户ID</p>
            <span>{{oneData.u_id}}</span>
          </div>
          <div class="sh_box display_flex justify-content_flex-justify align-items_center padding-tb">
            <p>用户昵称</p>
            <span>{{oneData.nickname}}</span>
          </div>
          <div class="sh_box display_flex justify-content_flex-justify align-items_center padding-tb">
            <p>累计赠送礼物</p>
            <div class="display_flex align-items_center margin-top-xs">
                <img src="img/zuan.png" alt="">
                <span class="margin-left-xs">{{oneData.total_value}}</span>
            </div>
          </div>
          <div class="sh_box display_flex justify-content_flex-justify align-items_center padding-tb">
            <p>邀请人数</p>
            <span>{{oneData.s_invite_sum}}</span>
          </div>
          <div class="sh_box display_flex justify-content_flex-justify align-items_center padding-tb">
            <p>邀请人累计赠送礼物</p>
            <div class="display_flex align-items_center margin-top-xs">
                <img src="img/zuan.png" alt="">
                <span class="margin-left-xs">{{oneData.s_total_value}}</span>
            </div>
          </div>
          <div class="sh_box display_flex justify-content_flex-justify align-items_center padding-tb">
            <p>邀请等级</p>
            <span>I级</span>
          </div>
        </div>
        <div class="sh_more display_flex justify-content_flex-end align-items_center margin-lr-sm">
          <p @click="goDetail(oneData)">查看详情</p>
          <img src="img/det.png" alt="" @click="goDetail(oneData)">
        </div>
      </template>

    </template>


  </div>
</template>

<script>
import "@/assets/css/base.css"
import "@/assets/css/index.css"
import {searchInvite} from "@/api/api";
import NavBar from '@/components/navBar.vue';
export default {
  name: 'search',
  components: {
    NavBar
  },
  data() {
    return {
      tetInput:'',
      showSearch:true,
      listData:[],
      twoData:{},
      oneData:{}
    };
  },
  mounted(){
    this.$nextTick(()=>{
      this.$refs['input'].focus()
    })
  },
  methods: {
    fetchData() {
      const Params = {
        content:this.tetInput
      }
      searchInvite(Params).then(response => {
        this.$route.meta.title = this.tetInput
        this.showSearch = false
        if(response.data.length>1){
          if(response.data[0].level ==2){
            this.twoData = response.data[0]
            this.oneData = response.data[1]
          }else{
            this.oneData = response.data[0]
            this.twoData = response.data[1]
          }
        }else{
          this.listData = response.data
        }
      },
      error => {
        this.$toast.fail(error)
      }).catch((error) => {
        this.$toast.fail(error)
      })
    },
    goBack(){
      this.$route.meta.title = '搜索'
      this.$router.go(-1)
    },
    tapToSearch(){
      if(this.tetInput!= ''){
        this.fetchData()
      }
    },
    goDetail(item){
      this.$router.push({path:'/lastInvite',query: {id:item.user_id,invite_sum:item.s_invite_sum,total_value:item.total_value,nickname:item.nickname}})
    }

  },
};
</script>